<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row style="background-color: #fafafa" class="mb-3">
          <v-col
            v-if="eventCategoryCollection"
            cols="12"
            sm="3"
            md="2"
            class="text-right"
          >
            <!--<v-switch
              @change="handleSwitchActiveEventsShow"
              :disabled="loadingTable"
              v-model="activeEventsShow"
              label="Lejárt események is"
            ></v-switch>-->
            <v-switch
              :disabled="loadingTable"
              v-model="options.showArchiveEvent"
              label="Lejárt események"
            ></v-switch>
          </v-col>
          <v-col
            v-if="eventCategoryCollection"
            cols="12"
            sm="3"
            md="2"
            class="text-right"
          >
            <v-switch
              :disabled="loadingTable"
              v-model="options.main_page"
              label="Főoldalon megjelenik"
            ></v-switch>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <div class="d-flex flex-row ml-auto">
              <v-text-field
                v-model="searchTerm"
                append-icon="search"
                label="Keresés az eseményekben"
                single-line
                hide-details
                class="pb-5"
                @change="handleSearch"
              ></v-text-field>
              <v-icon
                class="align-self-center mb-3 mx-3"
                color="primary"
                small
                @click="
                  searchTerm = null;
                  options.searchTerm = null;
                "
              >
                flaticon2-delete
              </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row style="background-color: #f6f5f4">
          <v-col
            class="py-1"
            cols="12"
            sm="8"
            md="8"
            v-if="eventCategoryCollection"
          >
            <p
              class="py-1 my-0 header-category"
              :class="{ active: !options.category_id }"
              @click="handleResetHeaderCategoryFilter"
            >
              MINDEN KATEGÓRIA
            </p>
          </v-col>
        </v-row>
        <v-row class="mb-5" style="background-color: #fafafa">
          <v-col cols="12" v-if="eventCategoryCollection">
            <v-row>
              <v-col
                cols="12"
                sm="3"
                md="2"
                class="py-1 header-category"
                v-for="category in eventCategoryCollection"
                :key="category.id"
              >
                <span
                  @click.stop="handleSelectHeaderCategory(category)"
                  :class="{ active: options.category_id == category.id }"
                  >{{ category.name }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="eventCollection"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loadingTable"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [20, 50, 100],
          }"
          @click:row="handleClickItem"
          :item-class="dataTableItemClass"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <EventModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                :eventCategoryCollection="eventCategoryCollection"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></EventModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:item.tableCategories="{ item }">
            <span v-for="category in item.categories" :key="category.id">
              {{ getEventCategoryByID(category).name }}
            </span>
          </template>

          <template v-slot:item.status="{ item }">
            <span v-if="statuses">
              {{ $t("STATUSES." + statuses.events.event[item.status].value) }}
            </span>
          </template>

          <!-- <template v-slot:item.date_from="{ item }">
            <span v-if="item && item.date_from">
              {{
                new Date(item.date_from).toLocaleString("hu-HU", {
                  timeZone: "UTC",
                })
              }}
            </span>
          </template>
          <template v-slot:item.date_to="{ item }">
            <span v-if="item && item.date_to">
              {{
                new Date(item.date_to).toLocaleString("hu-HU", {
                  timeZone: "UTC",
                })
              }}
            </span>
          </template> -->

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import ApiService from "@/core/services/api.service";

import i18nService from "@/core/services/i18n.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import EventModalForm, { initialFormData } from "./EventModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "events.event.";

export default {
  name: "Event",
  components: { EventModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      searchTerm: null,
      cardTitle: this.$t("MENU.EVENTS"),
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }).lang,
      routePath: "/cms/event/event/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",
          sortable: false,
        },
        // {
        //   text: this.$t("TABLE.created_at"),
        //   value: "created",
        // },
        {
          text: this.$t("TABLE.from"),
          value: "date_from",
        },
        {
          text: this.$t("TABLE.to"),
          value: "date_to",
        },
        {
          text: this.$t("TABLE.categories"),
          value: "tableCategories",
          sortable: false,
        },

        { text: this.$t("TABLE.status"), value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      eventCollection: [],
      totalItems: 0,
      options: {
        category_id: null,
        date_from: new Date(),
        created_at_date_from: null,
        created_at_date_to: null,

        itemsPerPage: 20,
        //my_recently_modified_items: true,

        searchTerm: null,
        sortBy: ["date_from"],
        sortDesc: [true],
        status: null,
        showArchiveEvent: false,
        main_page: false,
      },
    };
  },

  computed: {
    ...mapGetters([
      // "eventCollection",
      "eventCategoryCollection",
      "statuses",
      "getEventCategoryByID",
    ]),
    endPoint() {
      return "events/admin/" + SiteService.getActiveSiteId() + "/event/";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    options: {
      handler() {
        if (!this.loadingTable) this.fetchEvent();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(["fetchEventCategory"]),

    handleSaveModalForm(id = null) {
      this.modalData.editedId = null;
      if (id) {
        this.modalData.editedId = id;
      }
      // this.handleCloseModalForm();
    },

    fetchEvent() {
      this.loadingTable = true;

      return ApiService.post(
        "events/admin/" + SiteService.getActiveSiteId() + "/backend_items/",
        this.options
      )
        .then((data) => {
          this.eventCollection = data.data.collection.map((item) => {
            // item.created = item.created_at.substring(0, 16).replace("T", " ");

            item.date_from = !item.date_from
              ? ""
              : new Date(item.date_from).toLocaleString("hu-HU", {
                  timeZone: "Europe/Budapest",
                });
            item.date_to = !item.date_to
              ? ""
              : new Date(item.date_to).toLocaleString("hu-HU", {
                  timeZone: "Europe/Budapest",
                });

            if (item.translations) {
              let translations = item.translations[this.selectedLocale];
              if (translations) {
                Object.keys(translations).forEach((property) => {
                  item[property] =
                    item.translations[this.selectedLocale][property];
                });
                item.translated = true;
              } else {
                for (const [key, value] of Object.entries(
                  item.translations[Object.keys(item.translations)[0]]
                )) {
                  if ("attachments" != key) {
                    item[key] = value;
                  }
                }
              }
            }

            return item;
          });

          this.totalItems = data.data.totalItems;
        })
        .catch((error) => {
          if (error.response) {
            let errors = error.response.data;
            if (errors) {
              for (let field in errors.errors) {
                this.setError(field, errors.errors[field][0]);
              }
              console.log(this.messages);
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log("Error!: ", error);
          // this.modalData.loading = false;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    handleSearch() {
      if (this.searchTerm.length > 2) {
        this.options = {
          ...this.options,
          searchTerm: this.searchTerm,
          //date_from: this.getDateFrom(),
          locale: this.selectedLocale,
          my_recently_modified_items: null,
          status: null,
          sortDesc: [true],
          sortBy: ["date_from"],
        };
      }
    },

    fetchModel() {
      this.fetchEventCategory();
      return this.fetchEvent();
    },

    handleSelectHeaderCategory(category) {
      if (this.loadingTable) return null;
      this.options = {
        ...this.options,
        sortDesc: [true],
        //date_from: this.getDateFrom(),
        sortBy: ["date_from"],
        searchTerm: null,
        category_id: category.id,
      };
    },

    handleResetHeaderCategoryFilter() {
      if (this.loadingTable) return null;
      this.options.category_id = null;
    },

    /*handleSwitchActiveEventsShow() {
      this.activeEventsShow = !!this.activeEventsShow;

      this.options.date_from = this.getDateFrom();
    },*/

    /*getDateFrom() {
      if (this.activeEventsShow) return null;

      return new Date();
    },*/
    dataTableItemClass(item) {
      if (item.archive_event) return "archiveItemClass";

      return item.accentuated ? "accentuated" : "";
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>

<style scoped>
.header-category:hover {
  color: #e33354;
  cursor: pointer;
}

.active {
  font-weight: 600;
  color: #e33354;
}
</style>

<style lang="css">
.archiveItemClass {
  color: red;
}
.accentuated {
  color: green;
}
</style>